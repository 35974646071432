const firebaseConfig = {
  apiKey: "AIzaSyDAIpF5hg2U2MtsP9QZBjjOQ5tsAMErVAE",
  authDomain: "grange-visitor-registration.firebaseapp.com",
  databaseURL: "https://grange-visitor-registration.firebaseio.com",
  projectId: "grange-visitor-registration",
  storageBucket: "grange-visitor-registration.appspot.com",
  messagingSenderId: "276746702396",
  appId: "1:276746702396:web:360cb3ab39e6ccf67c7d39"
};

  export default firebaseConfig;